import React, {useEffect, useState} from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import routerProps from "../_helpers/routerProps";
import Header from "../common/Header/Header";
import {Background} from "./Background";
import Footer from "../common/Footer/Footer";
import jsonContent from "../_helpers/contentText.json"
import { ReactNotifications } from 'react-notifications-component'
import {useHistory, useLocation} from "react-router";

export default function Root(){
    const mobile_screen_size = 900;
    const mobile_screen = mobile_screen_size+'px';
    const [mobile, setMobile] = useState(false);
    const [pathname, setPathname] = useState("/");
    const contentText = jsonContent

    useEffect(() => (window.onresize = () => {
        if(window.innerWidth >= mobile_screen_size){
            setMobile(false);
        }else{
            setMobile(true);
        }
    }), []);

    return(
        <BrowserRouter>
            <Background pathname={pathname}>
                <ReactNotifications />
                <Header contentText={contentText} mobile_screen={mobile_screen} pathname={pathname}/>
                <Switch>
                    <Route path ={routerProps.home.path}
                           children={<routerProps.home.element setPathname={setPathname}
                           mobile_screen={mobile_screen} mobile={mobile} contentText={contentText}/>} exact />
                    <Route path={routerProps.thank_you.path}
                           children={<routerProps.thank_you.element mobile_screen={mobile_screen}
                               setPathname={setPathname} contentText={contentText} />} exact />
                    <Route  path={routerProps.portfolio.path}
                           children={<routerProps.portfolio.element mobile_screen={mobile_screen}
                                setPathname={setPathname} mobile={mobile} contentText={contentText} />} exact />
                    <Route children={<routerProps.page_404.element setPathname={setPathname}
                            contentText={contentText} />} />
                </Switch>
            </Background>
            <Footer mobile_screen={mobile_screen} contentText={contentText}/>
        </BrowserRouter>
    )
}
