import styled from "styled-components";

export const StyledFooter = styled.div`
  height: 380px;
  border-top: 2px solid #0081FE;
  .footer{
    height: 100%;
    background-color: white;
  }
  .footer_1{
    background-color: white;
    height: 100%;
  }
  @media (min-width: 0px) {
    .footer_1{
      height: 75%;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .footer_1{
      height: 100%;
    }
  }
  .footer_1_row_1_items {
    position: absolute;
    margin: auto;
    height: 100%;
    width: 100%;
    top: 40%;
    text-align: center;
  }
  .footer_1_row_1{
    position: relative;
    height: 60%;
    width: 100%;
  }
  .footer_1_row_2{
    align-items: center;
    justify-content: center;
    margin: auto;
    display: flex;
    color: #A1A1A1;
    height: 40%;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  .footer_social_buttons{
    padding-top: 7px;
  }
  .footer_2{
    background-color: #0063E4;
    height: 100%;
    color: white;
  }
  @media (min-width: 0px) {
    .footer_2{
      height: 90%;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .footer_2{
      height: 100%;
    }
  }
  .footer_2_items{
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .footer_2_address{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .footer_2_email{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }
  .footer_2_working_hours{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .footer_2_workdays{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
  }
  .footer_2_workhours{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    margin-left: 12px;
  }
  .footer_2_items div{
    margin-top: 10px;
  }
  .footer_3{
    background-color: #E5E5E5;
    margin: auto;
  }
  .footer_buttons{
    color: #1C2A33;
    display: block;
    font-style: normal;
    font-family: Montserrat, serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    margin: 15px auto;
  }
`;
