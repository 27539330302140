import styled from 'styled-components';

export const Container4Styles = styled.div`
  @media (min-width: 0px) {
    .container{
      height: fit-content;
      margin: 42px 0px;
      background-color: #0063E4;
      width: fit-content;
    }
    .container_1{
      padding: 52px 30px;
    }
    .container_2{
      padding-top: 43px;
      padding-left: 84px;
      padding-right: 30px;
    }
    .container_3{
      padding: 40px 30px 0px 84px;
    }
    .container_4{
      padding: 40px 30px 52px 84px;
    }
    .container_2_img{
      position: absolute;
      margin-left: -60px;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .container{
      height: fit-content;
      margin: 150px 100px;
      background-color: #0063E4;
      width: fit-content;
    }
    .container_1{
      padding-top: 91px;
      padding-left: 94px;
    }
    .container_2{
      padding-top: 155px;
      padding-left: 165px;
      padding-right: 94px;
    }
    .container_3{
      padding: 96px 50px 91px 177px;
    }
    .container_4{
      padding: 96px 50px 91px 165px;
    }
    .container_2_img{
      position: absolute;
      margin-left: -70px;
    }
  }
  .container_1_1{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 130%;
    color: #FFFFFF;
  }
  .container_inner_text{
    padding-top: 30px;
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    opacity: 0.7;
  }
  .container_title{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 38px;
    color: #FFFFFF;
  }
  .demo_button{
    font-size: 16px;
    font-style: normal;
    font-family: Montserrat, serif;
    font-weight: 700;
    line-height: 20px;
    padding: 14px 28px;
    text-transform: initial;
    width: 202px;
    height: 48px;
    color: white;
    display: flex;
    align-items: center;
    border: 1px solid white;
    border-radius: 0px;
    filter: drop-shadow(0px 10px 30px rgba(56, 29, 219, 0.2));
    margin-top: 35px;
  }
`;
