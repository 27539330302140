import ThemeProvider from "../common/ThemeProvider/ThemeProvider";
import Root from "./Root";

function App() {
  return (
    <ThemeProvider>
      <Root/>
    </ThemeProvider>
  );
}

export default App;
