import React from 'react';
import ReactDOM from 'react-dom';
import './_css/index.css';
import App from './_app/App';
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
