import styled from 'styled-components';

export const Background = styled.div`
  margin: 0 0;
  background-image: ${props => props.pathname === '/' ? 'url("/images/home_back.svg")' : 'url("/images/back.svg")'};
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: white;
  height: ${props => props.pathname === '/' || props.pathname === '/portfolio' ? 'fit-content' : '870px'};
`;

