import {SlideStyles} from "./SlideStyles";
import {Box, Grid} from "@mui/material";
import React from "react";

export default function Slide({mobile_screen,  contents}){
    return(
        <SlideStyles mobile_screen={mobile_screen}>
            <Grid container spacing={0} className="container">
                <Box component="img" src="/images/cubic.svg" className="back_cubic"></Box>
                <Grid item xs={12} md={12} className="container_1" dangerouslySetInnerHTML={{__html: contents['title']}}>
                </Grid>
                <Grid item xs={12} md={12} lg={6} className="container_2">
                    <div className="container_2_1">
                        {contents['title2']}
                    </div>
                    <div className="container_2_2" dangerouslySetInnerHTML={{__html: contents['description']}}>
                    </div>
                    <div className="container_2_3">
                        {contents['advantage1'] &&
                          <>
                              <Box component="img" src="/images/analytics.svg" className="text_icon"/>
                              {contents['advantage1']}
                          </>
                        }
                    </div>
                    <div className="container_2_3">
                        {contents['advantage2'] &&
                          <>
                              <Box component="img" src="/images/analytics.svg" className="text_icon"/>
                              {contents['advantage2']}
                          </>
                        }
                    </div>
                    <div className="container_2_3">
                        {contents['advantage3'] &&
                          <>
                              <Box component="img" src="/images/analytics.svg" className="text_icon"/>
                              {contents['advantage3']}
                          </>
                        }
                    </div>
                    <div className="container_2_3">
                        {contents['advantage4'] &&
                          <>
                              <Box component="img" src="/images/analytics.svg" className="text_icon"/>
                              {contents['advantage4']}
                          </>
                        }
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6} className="container_3">
                    <div className="container_3_inner">
                        {contents['stats1'] &&
                          <div className="container_3_1 con1">
                              <Box component="img" src="/images/check.svg" className="check_icon"/>
                              <div dangerouslySetInnerHTML={{__html: contents['stats1']}}></div>
                          </div>
                        }
                        {contents['stats2'] &&
                          <div className="container_3_1 con2">
                              <Box component="img" src="/images/check_white.svg" className="check_icon"/>
                              <div dangerouslySetInnerHTML={{__html: contents['stats2']}}></div>
                          </div>
                        }
                        {contents['stats3'] &&
                          <div className="container_3_1 con2">
                              <Box component="img" src="/images/check_white.svg" className="check_icon"/>
                              <div dangerouslySetInnerHTML={{__html: contents['stats3']}}></div>
                          </div>
                        }
                    </div>
                </Grid>
            </Grid>
        </SlideStyles>
    )
}
