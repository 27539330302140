import styled from 'styled-components';

export const ThankYouStyles = styled.div`
  margin: 0px 0px;
  height: 731px;
  padding: 30px;
  .images{
    position: relative;
    height: 100%;
    width: 100%;
  }
  .content{
    color: white;
    margin: auto;
    display: block;
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    text-align: center;
  }
  @media (min-width: 0px) {
    .title1{
      font-size: 54px;
      line-height: 54px;
    }
    .title2{
      font-size: 54px;
      line-height: 54px;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .title1{
      font-size: 78px;
      line-height: 95px;
    }
    .title2{
      font-size: 78px;
      line-height: 95px;
    }
  }
  .title1{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 800;
    text-align: center;
    letter-spacing: -0.045em;
    color: #FFFFFF;
    text-shadow: 0px 2px 0px rgba(34, 34, 34, 0.1);
  }
  .title2{
    margin-left: 20px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 800;
    text-align: center;
    letter-spacing: -0.045em;
    color: #FF8383;
    text-shadow: 0px 2px 0px rgba(34, 34, 34, 0.1);
  }
  .go_home_button{
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    padding-left: 5px;
    line-height: 20px;
    text-transform: initial;
    color: #FF8383;
  }
  .content a{
    text-decoration: none;
  }
`;
