import styled from 'styled-components';

export const Page404Styles = styled.div`
  margin: 0px 0px;
  height: 731px;
  padding: 30px;
  .images{
    position: relative;
    height: 100%;
    width: 100%;
  }
  .content{
    color: white;
    margin: auto;
    display: block;
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    text-align: center;
  }
  .go_home_button{
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: initial;
    color: #FF8383;
  }
  .content a{
    text-decoration: none;
  }
`;
