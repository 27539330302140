import styled from 'styled-components';

export const Container3Styles = styled.div`
  @media (min-width: 0px) {
    .container_3{
      height: fit-content;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 66px;
      background-color: white;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .container_3{
      height: fit-content;
      padding-left: 235px;
      padding-right: 235px;
      padding-top: 100px;
      background-color: white;
    }
  }
  .container_3_1{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 130%;
    text-align: center;
    color: #1C2A33;
  }
  .container_3_2{
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(28, 42, 51, 0.7);
  }
  .container_3_3_2{
    padding-top: 40px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 38px;
  }
  .container_3_3_3{
    padding-top: 17px;
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.7;
  }
  @media (min-width: 0px) {
    .container_3_2{
      padding-top: 20px;
    }
    .container_3_3{
      height: fit-content;
      padding: 30px 30px;
      color: #1C2A33;
      position: relative;
    }
    .container_3_3_2{
      padding-top: 20px;
    }
    .container_3_3_3 {
      padding-top: 10px;
    }
    .container_3_3_4{
      padding-top: 21px;
    }
    .container_3_4{
      height: fit-content;
      width: 300px;
      background-color: #FB5252;
      padding: 30px 30px;
      position: relative;
      color: white;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .container_3_2 {
      padding-top: 40px;
    }
    .container_3_3{
      position: relative;
      height: 480px;
      margin-top: 60px;
      padding: 33px 30px;
      color: #1C2A33;
    }
    .container_3_3_2 {
      padding-top: 40px;
    }
    .container_3_3_3 {
      padding-top: 17px;
    }
    .container_3_4{
      height: 480px;
      width: 300px;
      margin-top: 60px;
      background-color: #FB5252;
      padding: 33px 30px;
      position: relative;
      color: white;
    }
    .container_3_3_4{
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: 20px;
      padding-left: 20px;
    }
  }
  .container_3_4_box{
    position: absolute;
    bottom: 0;
    right: 0;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .divider{
    margin-top: 87px;
    overflow: hidden;
    text-align: center;
  }
`;
