import styled from 'styled-components';

export const Slide1Styles = styled.div`
  @media (min-width: 0px) {
    .container{
      height: fit-content;
      width: fit-content;
      margin: 40px 30px 40px 30px;
    }
    .container_2_1{
      text-align: center;
    }
    .container_2_2{
      text-align: center;
    }
    .container_3_inner{
      margin-left: 0px;
      box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.09);
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .container{
      height: fit-content;
      width: fit-content;
      margin: 100px 150px 100px 226px;
    }
    .container_2_1{
      text-align: initial;
    }
    .container_2_2{
      text-align: initial;
    }
    .container_3_inner{
      margin-left: 77px;
      box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.09);
    }
  }
  .container_1{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 130%;
    text-align: center;
    color: #1C2A33;
  }
  .container_2{
    margin-top: 45px;
  }
  .container_2_1{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #0063E4;
  }
  .container_2_2{
    padding-top: 14px;
    padding-bottom: 31px;
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(28, 42, 51, 0.7);
  }
  .container_2_3{
    padding-bottom: 46px;
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1C2A33;
    padding-left: 48px;
    position: relative;
  }
  .text_icon{
    position: absolute;
    left: 0;
    top: 0;
  }
  .container_3{
    margin-top: 45px;
  }
  .container_3_1{
    position: relative;
    padding: 58px 80px 50px 82px;
  }
  .con1{
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(28, 42, 51, 0.7);
  }
  .con2{
    background-color: #FB5252;
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
  }
  #text1{
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FB5252;
  }
  #text2{
    font-weight: 700;
    font-size: 30px;
    line-height: 24px;
  }
  #text3{
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: white;
  }
  .check_icon{
    margin-left: 34px;
    margin-top: 58px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .back_cubic{
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -67px;
    margin-left: -192px;
  }
`;
