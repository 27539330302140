import styled from 'styled-components';

export const Container1Styles = styled.div`
  overflow: hidden;
  @media (min-width: 0px) {
    .container_1{
      height: fit-content;
      padding: 30px 20px;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .container_1{
      height: 731px;
      padding-left: 150px;
      padding-top: 20px;
    }
  }
  @media (min-width: 0px) {
    .container_1_1{
      transform: translateY(0);
    }
    .container_1_1_content{
      text-align: center;
      max-width: 100%;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .container_1_1{
      transform: translateY(15%);
      width: 100%;
    }
    .container_1_1_content{
      text-align: initial;
      max-width: 78%;
    }
  }
  .container_1_1_1{
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .container_1_1_1 div{
    display: inline-block;
    margin-left: 10px;
  }
  .container_1_1_2{
    margin-top: 20px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 49px;
    text-transform: capitalize;
    color: #FF8383;
    text-shadow: 0px 2px 0px rgba(34, 34, 34, 0.1);
  }
  .container_1_1_3{
    margin-top: 30px;
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 38px;
    color: #FFFFFF;
  }
  .container_1_1_4{
    margin-top: 40px;
  }
  .container_1_2{
    padding-top: 25px;
  }
  .container_1_2_card{
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 20px 100px rgba(34, 34, 34, 0.15);
  }
  @media (min-width: 0px) {
    .container_1_2_card{
      width: fit-content;
      height: 566px;
      margin: auto;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .container_1_2_card {
      width: 463px;
      height: 566px;
      margin: initial;
    }
  }
  .container_1_2_card_img{
    position: absolute;
    width: 76px;
    height: 526px;
    right: 20px;
    top: 20px;
  }
  .container_1_2_card_img2{
    position: absolute;
    right: 28px;
    bottom: 47px;
  }
  .container_1_2_card_1{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 38px;
    color: #1C2A33;
    margin-top: 60px;
    margin-left: 60px;
    margin-bottom: 10px;
  }
  .container_1_2_card_2{
    margin-left: 60px;
    margin-top: 20px;
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1C2A33;
    width: 260px;
    height: 48px;
  }
  .container_1_2_card_2 fieldset{
    border-radius: 0;
  }
  .container_1_2_card_3{
    margin-left: 60px;
    margin-top: 30px;
  }
  .container_1_2_card_3_button{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    text-transform: initial;
    padding: 14px 28px;
    width: 114.27px;
    height: 48px;
    background: #0081FE;
    box-shadow: 0px 10px 30px rgba(0, 99, 228, 0.2);
    border-radius: 0px;
  }
  .read_more_button{
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: initial;
    padding-left: 5px;
    color: #FF8383;
  }
`;
