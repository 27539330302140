import styled from 'styled-components';

export const OrderCallStyles = styled.div`
  @media (min-width: 0px) {
    .close_modal{
      position: absolute;
      top:0;
      right:0;
      margin-top: 22px;
      margin-right: 20px;
    }
    .container{
      padding: 65px 27px;
    }
    .text_fields{
      width: 317px;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .close_modal{
      position: absolute;
      top:0;
      right:0;
      margin-top: 34px;
      margin-right: 38px;
    }
    .container{
      padding: 90px 100px;
    }
    .text_fields{
      width: 416px;
    }
  }
  .container{
    position: absolute;
    top: 50%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%, -50%);
    background: #FF8383;
  }
  .text_fields{
    margin: 14px 0px;
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    height: 48px;
  }
  .text_f{
    width: 100%;
  }
  .text_fields fieldset{
    border-radius: 0;
  }
  .title{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
  }
  .description{
    margin-top: 11px;
    margin-bottom: 14px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: #FFFFFF;
  }
  .validation{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 5px;
  }
  .send_button{
    height: 48px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #FF8383;
    text-transform: initial;
    border-radius: 0px;
    background: #FFFFFF;
    padding: 10px 30px;
    margin-top: 17px;
  }
`;
