import React, {useState} from "react";
import {HeaderStyles} from "./HeaderStyles";
import {
    AppBar,
    Box,
    Button,
    Container, Link,
    Toolbar,
} from "@mui/material";
import MobileMenu from "./MobileMenu/MobileMenu";
import OrderCall from "./OrderCall/OrderCall";
import {NavLink} from "react-router-dom";

export default function Header({mobile_screen, pathname, contentText}){

    const contents = contentText['NavBar'];
    const [openMenu, setOpenMenu] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenMenu(open);
    };



    return(
        <HeaderStyles mobile_screen={mobile_screen} pathname={pathname}>
            <AppBar position="static" color="transparent" elevation={0} className="header_menu">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <Box component="img" sx={{ display: { xs: 'flex', md: 'none' } }}
                                 src="/images/logo.svg">
                            </Box>
                        </Box>
                        <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                            <Button
                                onClick={() => {setOpenMenu(true)}} className="header_menu_button"
                                sx={{ my: 2, color: 'white', display: { xs: 'flex', md: 'none' }}}
                            >
                                {contents['menu_button']}
                            </Button>
                        </Box>
                        <NavLink to='/'>
                            <Box component="img" sx={{ display: { xs: 'none', md: 'block' } }} className="p-85" src="/images/logo.svg"></Box>
                        </NavLink>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {contents['links'].map((link, index) => (
                                <NavLink key={index} to={link['hash'] ? "/#"+link['hash'] : link['link']}>
                                    <Button className="header_buttons"
                                            sx={{
                                                ':hover': {
                                                    color: '#FF8383',
                                                },
                                                my: 2
                                            }}>
                                        {link['title']}
                                    </Button>
                                </NavLink>
                            ))}
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <Button
                                onClick={handleOpenModal} className="header_order_call"
                                sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex' }}}
                            >
                                {contents['order_button']}
                            </Button>
                        </Box>
                    </Toolbar>
                    <MobileMenu open={openMenu} toggleDrawer={toggleDrawer}
                                handleOpen={handleOpenModal} contents={contents}/>
                    <OrderCall open={openModal} setOpenMenu={setOpenMenu} contents={contents["order_modal"]}
                               mobile_screen={mobile_screen} handleClose={handleCloseModal}/>
                </Container>
            </AppBar>
        </HeaderStyles>
    )
}
