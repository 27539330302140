import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {MobileMenuStyles} from "./MobileMenuStyles";
import {Grid} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {NavLink} from "react-router-dom";

export default function MobileMenu({open, toggleDrawer, handleOpen, contents}) {
    return (
        <div>
            <Drawer
                anchor={'top'}
                open={open}
                onClose={toggleDrawer(false)}
            >
                <MobileMenuStyles>
                <Box
                    sx={{ height: '100vh' }}
                    role="presentation"
                    onKeyDown={toggleDrawer(false)}
                >
                    <Grid container spacing={0} className="container">
                        <Grid item xs={12} className="container_1">
                            <Box component="img" src="/images/logo_mobile_menu.svg">
                            </Box>
                            <Button onClick={toggleDrawer(false)} className="menu_close_button">
                                <CloseRoundedIcon></CloseRoundedIcon>
                                {contents['close_button']}
                            </Button>
                        </Grid>
                        <Grid item xs={12} className="container_2">
                            {contents['links'].map((link, index) => (
                                <NavLink key={index} to={link['hash'] ? "/#"+link['hash'] : link['link']}>
                                    <Button onClick={toggleDrawer(false)}
                                        className="header_menu_buttons"
                                        sx={{ my: 2, textAlign: 'center'}}>
                                        {link['title']}
                                    </Button>
                                </NavLink>
                            ))}
                            <Button
                                onClick={handleOpen} className="header_menu_order_call"
                                sx={{ my: 2, display: { xs: 'flex', md: 'none' }}}
                            >
                                {contents['order_button']}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                </MobileMenuStyles>
            </Drawer>
        </div>
    );
}
