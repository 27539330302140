import styled from 'styled-components';

export const Container6Styles = styled.div`
  
  height: fit-content;
  background-color: white;
  @media (min-width: 0px) {
    margin-bottom: 73px;
  }
  @media (min-width: ${props => props.mobile_screen}) {
    margin-bottom: 92px;
  }
  .MuiMobileStepper-dot{
    border-radius: 20%;
    width: 29px;
    height: 4px;
  }
`;
