import paths from "./paths";
import HomePage from "../Pages/HomePage/HomePage";
import ThankYou from "../Pages/ThankYou/ThankYou";
import Page404 from "../Pages/Page404/Page404";
import Portfolio from "../Pages/Portfolio/Portfolio";

const routerProps ={
    home: {
        element: HomePage,
        path: paths.home,
    },
    thank_you: {
        element: ThankYou,
        path: paths.thank_you,
    },
    portfolio: {
        element: Portfolio,
        path: paths.portfolio,
    },
    page_404: {
        element: Page404,
        path: paths.all,
    }
}
export default routerProps
