import React, {useEffect} from "react";
import {Box, Button} from "@mui/material";
import {NavLink, useLocation} from "react-router-dom";
import {ThankYouStyles} from "./ThankYouStyles";

export default function ThankYou({setPathname, contentText, mobile_screen}){
    let location = useLocation();
    const contents = contentText['thank_you'];
    useEffect(() => {
        setPathname(location.pathname);
    }, [location]);

    return(
        <ThankYouStyles mobile_screen={mobile_screen}>
            <Box className="images">
                <div className="content">
                    <span className="title1">{contents['title1']}</span>
                    <span className="title2">{contents['title2']}</span>
                    <div style={{marginTop: '20px'}}></div>
                        {contents['description1']}
                    <div style={{marginTop: '20px'}}></div>
                        {contents['description2']}
                    <div style={{marginTop: '20px'}}></div>
                    <NavLink to="/" sx={{textDecoration: 'none'}}>
                        <Button sx={{"&:hover": {
                                backgroundColor: "rgba(255, 255, 255,0.1)",
                                borderRadius: "4px"
                            }, borderRadius: "4px"}}>
                            <span className="go_home_button">{contents['go_home_button']}</span>
                            <img src="/images/go_to_home.svg" style={{padding:"5px"}}/>
                        </Button>
                    </NavLink>
                </div>
            </Box>
        </ThankYouStyles>
    )
}
