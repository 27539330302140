import {Container6Styles} from "./Container6Styles";
import SwipeableTextMobileStepper from "./Stepper";

export default function Container6({mobile_screen, contentText}){
    const contents = contentText['HomePage']['Container6'];
    return(
        <Container6Styles mobile_screen={mobile_screen}  id="PORTFOLIO">
            {/*<SwipeableTextMobileStepper mobile_screen={mobile_screen} contents={contents}/>*/}
        </Container6Styles>
    )
}
