import React, { useEffect, useMemo } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import ThemeConfig from './ThemeConfig';
import { ThemeWrapper } from './ThemeStyle';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as StyledProvider } from 'styled-components';


export default function ThemeProvider({ children }) {

  const theme = useMemo(
    () => createTheme(ThemeConfig(false)),
    []
  );

  useEffect(() => {
    try {
      document.getElementById('app').style.backgroundColor =
        theme.palette.background.default;
    } catch (e) {}
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeWrapper theme={theme}>
        <StyledProvider theme={theme}>{children}</StyledProvider>
      </ThemeWrapper>
    </MuiThemeProvider>
  );
}
