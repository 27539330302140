import {Box, Button, Card, CardContent, Grid, Paper, TextField} from "@mui/material";
import React from "react";
import 'react-multi-carousel/lib/styles.css';
import {Container3Styles} from "./Container3Styles";

export default function Container3({mobile, mobile_screen, contentText}){
    const contents = contentText['HomePage']['Container3'];
    return(
        <Container3Styles mobile_screen={mobile_screen}>
            <Grid container spacing={0} className="container_3" id="SERVICES">
                <Grid item xs={12} md={12} className="container_3_1">
                    {contents['title']}
                </Grid>
                <Grid item xs={12} md={12} className="container_3_2">
                    {contents['description']}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} className="container_3_3">
                    <Box component="img" src="/images/arrow.svg" className="container_3_3_1"></Box>
                    <div className="container_3_3_2">
                        {contents['service1_title']}
                    </div>
                    <div className="container_3_3_3">
                        {contents['service1_description']}
                    </div>
                    {/*<div>*/}
                    {/*    <Box component="img" src="/images/arrow_button.svg" className="container_3_3_4"></Box>*/}
                    {/*</div>*/}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} className="container_3_4">
                    <Box component="img" src="/images/our_services_2.svg" className="container_3_4_1"></Box>
                    <div className="container_3_3_2">
                        {contents['service2_title']}
                    </div>
                    <div className="container_3_3_3">
                        {contents['service2_description']}
                    </div>
                    {/*<div>*/}
                    {/*    <Box component="img" src="/images/arrow_white.svg" className="container_3_3_4"></Box>*/}
                    {/*    <Box component="img" src="/images/our_services_2_box.svg" className="container_3_4_box"></Box>*/}
                    {/*</div>*/}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} className="container_3_3" sx={mobile ? {paddingLeft: '30px!important'} : {paddingLeft: '70px!important'}}>
                    <Box component="img" src="/images/phone.svg" className="container_3_3_1"></Box>
                    <div className="container_3_3_2">
                        {contents['service3_title']}
                    </div>
                    <div className="container_3_3_3">
                        {contents['service3_description']}
                    </div>
                    {/*<div>*/}
                    {/*    <Box component="img" src="/images/arrow_button.svg" className="container_3_3_4"></Box>*/}
                    {/*</div>*/}
                </Grid>
                {mobile &&
                    <Grid item xs={12} md={12} className="divider">
                        <Box component="img" src="/images/mobile_divider.png" className="container_2_4_img"></Box>
                    </Grid>
                }
            </Grid>
        </Container3Styles>
    )
}
