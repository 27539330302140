import {Box, Grid} from "@mui/material";
import React from "react";
import 'react-multi-carousel/lib/styles.css';
import {Container5Styles} from "./Container5Styles";
import IconButton from "@material-ui/core/IconButton";
import EmployeeStepper from "../Container5/Stepper";

export default function Container5({mobile, mobile_screen, contentText}){
    const contents = contentText['HomePage']['Container5'];
    const openUrl = (url) => {
      if(url){
        window.open(url, '_blank').focus();
      }
    }

    return(
        <Container5Styles mobile_screen={mobile_screen}>
            <Grid container spacing={0} className="container">
                <Grid item xs={12} md={12} className="container_1">
                    {contents['title']}
                </Grid>
                <Grid item xs={12} md={12} className="container_2">
                    {contents['description']}
                </Grid>
                {mobile
                    ?
                    <Grid item xs={12} md={12} className="container_3">
                        <EmployeeStepper contents={contents} Employee={Employee} openUrl={openUrl}/>
                    </Grid>
                    :
                    <Grid item xs={12} md={12} className="container_3">
                        {contents['employees'].map((employee, index) => {
                            return <Employee employee={employee} openUrl={openUrl} key={index}/>
                        })}
                    </Grid>
                }
            </Grid>
        </Container5Styles>
    )
}

function Employee({employee, openUrl}){
    return(
        <div className="paper">
            <div className="paper_img-div">
                <img src={employee['avatar']} className="paper_img"/>
            </div>
            <div className="paper_title">
                {employee['name']}
            </div>
            <div className="paper_job_position">
                {employee['position']}
            </div>
        </div>
    )
}
