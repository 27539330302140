import styled from 'styled-components';

export const MobileMenuStyles = styled.div`
  .container{
      padding: 24px 30px;
    height: 100%;
  }
  .menu_close_button{
    font-size: 14px;
    font-style: normal;
    font-family: Montserrat, serif;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    text-transform: uppercase;
    color: #FF8383;
    border: 1px solid #FF8383;
    border-radius: 0px;
    position: absolute;
    margin: 39px 30px;
    right: 0;
    top: 0;
  }
  .container_2{
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .header_menu_buttons{
    color: #1C2A33;
    display: block;
    font-style: normal;
    text-transform: uppercase;
    font-family: Montserrat, serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 31px auto;
  }
  .header_menu_order_call{
    font-size: 16px;
    font-style: normal;
    font-family: Montserrat, serif;
    font-weight: 700;
    line-height: 20px;
    padding: 14px 28px;
    text-transform: initial;
    width: 160px;
    height: 48px;
    color: #0063E4;
    border: 1px solid #0063E4;
    filter: drop-shadow(0px 10px 30px rgba(56, 29, 219, 0.2));
    border-radius: 0px;
    margin: 50px auto;
  }
`;
