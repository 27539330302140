import {Box, Button, Card, CardContent, Grid, TextField} from "@mui/material";
import React, {useState} from "react";
import {Container1Styles} from "./Container1Styles";
import {NavLink} from "react-router-dom";
import {SendToJivo} from "../../../../common/SendForm/SendToJivo";
import {useHistory} from "react-router";

export default function Container1({mobile_screen, contentText}){
    const contents = contentText['HomePage']['Container1'];
    const input_label = {
        style:{
            fontFamily: 'Raleway, serif',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#1C2A33',
            opacity: '0.3',
        }
    }
    const input_prop = {
        style: {
            height: '48px',
            padding: '0 12px',
        }
    }

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    let history = useHistory();

    const send_info = async () => {
        await SendToJivo(history, name, phone, email, company);
    }

    return(
        <Container1Styles mobile_screen={mobile_screen}>
            <Grid container spacing={0} className="container_1">
            <Grid item xs={12} md={6} className="container_1_1">
                <div className="container_1_1_content">
                    <div className="container_1_1_1">
                        <Box component="img" src="/images/star.svg"/>
                        <div>{contents['text1']}</div>
                    </div>
                    <div className="container_1_1_2">
                        {contents['title']}
                    </div>
                    <div className="container_1_1_3">
                        {contents['description']}
                    </div>
                    <div className="container_1_1_4">
                        <NavLink to={"/#ABOUT_US"}>
                            <Button sx={{"&:hover": {
                                    backgroundColor: "rgba(255, 255, 255,0.1)",
                                    borderRadius: "4px"
                                }, borderRadius: "4px", padding: "0px"}}>
                                <span className="read_more_button">{contents['read_more']}</span>
                                <img src="/images/read_more.svg" style={{padding:"5px"}}/>
                            </Button>
                        </NavLink>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6} className="container_1_2">
                <Card className="container_1_2_card">
                    <CardContent sx={{padding: '0px'}}>
                        <img src="/images/line.svg" className="container_1_2_card_img"/>
                        <img src="/images/home_card_comp.svg" className="container_1_2_card_img2"/>
                        <div className="container_1_2_card_1">
                            {contents['contact_us']}
                        </div>
                        <div className="container_1_2_card_2">
                            <TextField id="outlined-basic" label={contents['input_1']} variant="outlined"
                                InputLabelProps={input_label} inputProps={input_prop}
                                value={name} onChange={(e) => {setName(e.target.value)}}/>
                        </div>
                        <div className="container_1_2_card_2">
                            <TextField id="outlined-basic" label={contents['input_2']} variant="outlined"
                                InputLabelProps={input_label} inputProps={input_prop}
                                value={email} onChange={(e) => {setEmail(e.target.value)}}/>
                        </div>
                        <div className="container_1_2_card_2">
                            <TextField id="outlined-basic" label={contents['input_3']} variant="outlined"
                                InputLabelProps={input_label} inputProps={input_prop}
                                value={phone} onChange={(e)=>{setPhone(e.target.value)}}/>
                        </div>
                        <div className="container_1_2_card_2">
                            <TextField id="outlined-basic" label={contents['input_4']} variant="outlined"
                                InputLabelProps={input_label} inputProps={input_prop}
                                value={company} onChange={(e)=>{setCompany(e.target.value)}}/>
                        </div>
                        <div className="container_1_2_card_3">
                            <Button variant="contained" className="container_1_2_card_3_button" onClick={send_info}>
                                {contents['send_button']}
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        </Container1Styles>
    )
}
