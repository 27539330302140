import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {OrderCallStyles} from "./OrderCallStyles";
import {TextField} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import {SendToJivo} from "../../SendForm/SendToJivo";
import {useState} from "react";
import {useHistory} from "react-router";

export default function OrderCall({mobile_screen, open, setOpenMenu, handleClose, contents}) {
    const input_label = {
        style:{
            fontFamily: 'Raleway, serif',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '24px',
            color: 'white',
            opacity: '0.5',
        }
    }
    const input_prop = {
        style: {
            height: '48px',
            padding: '0 12px',
        }
    }
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    let history = useHistory();

    const send_info = async () => {
        if(await SendToJivo(history, name, phone)){
            setName('');
            setPhone('');
            handleClose();
            setOpenMenu(false);
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <OrderCallStyles mobile_screen={mobile_screen}>
                    <Fade in={open}>
                        <Box className="container">
                            <IconButton color="primary" className="close_modal"  onClick={handleClose}>
                                <img src="/images/close_modal.svg"/>
                            </IconButton>
                            <div className="title">
                                {contents['title']}
                            </div>
                            <div className="description" dangerouslySetInnerHTML={{__html: contents['description']}}></div>
                            <div className="text_fields">
                                <TextField id="outlined-basic" label={contents['input_name']} variant="outlined" className="text_f"
                                           InputLabelProps={input_label} inputProps={input_prop}
                                           value={name} onChange={(e) => {setName(e.target.value)}}/>
                            </div>
                            <div className="text_fields">
                                <TextField id="outlined-basic" label={contents['input_phone']} variant="outlined" className="text_f"
                                           InputLabelProps={input_label} inputProps={input_prop}
                                           value={phone} onChange={(e)=>{setPhone(e.target.value)}}/>
                            </div>
                            <div className="validation">
                                {contents['warning']}
                            </div>
                            <Button onClick={send_info} className="send_button" sx={{
                                ':hover': {
                                    bgcolor: 'white',
                                    opacity: '0.75',
                                },
                            }}>
                                {contents['send_button']}
                            </Button>
                        </Box>
                    </Fade>
                </OrderCallStyles>
            </Modal>
        </div>
    );
}
