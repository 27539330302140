import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Slide1 from "./Slides/Slide1";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper({mobile_screen, contents}) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const slides = contents.map(item => {
        return {
            component: <Slide1 mobile_screen={mobile_screen} contents={item}/>
        }
    });

    const maxSteps = slides.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const springConfig = {
        duration: '2s',
        easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
        delay: '0s'
    }

    return (
        <Box>
            <AutoPlaySwipeableViews
                springConfig={springConfig}
                interval={5000}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {slides.map((step, index) => (
                    <div key={index}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            step.component
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                backButton={<div></div>}
                nextButton={<div></div>}
            />
        </Box>
    );
}

export default SwipeableTextMobileStepper;
