export default function (isDark) {
  const primary = {
    main: isDark ? '#002c9c' : '#0081FE',
  };
  const secondary = { main: '#FB5252', dark: '#EB5757' };

  return {
    isDark: isDark,
    palette: {
      type: isDark ? 'dark' : 'light',
      primary: primary,
      darkPrimary: '#007675',
      success: { main: '#27AE60' },
      secondary: secondary,
      background: {
        default: isDark ? '#1C2A33' : '#FAFAFA',
        paper: isDark ? '#1C2A33' : '#FFFFFF',
      },
      text: {
        secondary: isDark ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      htmlFontSize: 16,
    },
    shape: { borderRadius: 6 },
    props: {
      MuiFormControl: { variant: 'outlined' },
      MuiButton: { disableFocusRipple: true },
      MuiButtonGroup: { disableElevation: true },
      MuiCard: { variant: 'outlined' },
      MuiSelect: { variant: 'outlined' },
      MuiAppBar: {
        position: 'static',
        color: 'default',
        elevation: 0,
      },
      MuiTextField: {
        variant: 'outlined',
      },
      MuiMenu: {
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      },
    },
    overrides: {
      MuiTableCell: {
        head: { fontWeight: 'bold' },
      },
      MuiBottomNavigationAction: {
        root: {
          '&$selected': {
            color: secondary.dark,
          },
        },
      },
      MuiMenuItem: {
        root: { fontSize: '15px' },
      },
      MuiPopover: {
        paper: {
          border: '0.5px solid rgba(118, 118, 118, 0.28)',
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 10px 37px',
        },
      },
      MuiPaper: {
        root: {
          color: isDark ? '#fff' : '#1C2A33',
        },
      },
      MuiToggleButton: {
        root: {
          width: '100%',
          textTransform: 'inherit',
          border: `1px solid rgba(${isDark ? '255, 255, 255,' : '0, 0, 0,'} 0.3)`,
          color: isDark ? '#ffffff' : '#000000',
          '&$selected': {
            backgroundColor: isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
            fontWeight: 600,
            color: isDark ? '#ffffff' : '#1C2A33',
            '&:hover': {
              // backgroundColor: primary.main,
            },
          },
          padding: '5px 20px',
        },
      },
      MuiPagination: { ul: { justifyContent: 'start' } },
      MuiPaginationItem: {
        root: {
          fontFamily: "'Maven Pro', sans-serif",
        },
      },
      MuiAppBar: {
        root: {
          backgroundColor: isDark ? '#1C2A33' : '#ffffff',
        },
      },
      MuiFormControlLabel: {
        root: {
          marginRight: 0,
        },
      },
      MuiCheckbox: {
        root: {
          padding: 0,
          marginRight: 7,
          marginLeft: 9,
        },
      },
      MuiToolbar: {
        root: {
          justifyContent: 'center',
        },
      },
      MuiButton: {
        root: {
          padding: '5px 20px',
          textTransform: 'inherit',
          whiteSpace: 'nowrap',
        },
        outlined: {
          border: `1px solid rgba(${isDark ? '255, 255, 255,' : '0, 0, 0,'} 0.3)`,
          padding: '5px 20px',
        },
        contained: {
          backgroundColor: isDark ? '#1C2A33' : '#ffffff',
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
        },
        text: {
          padding: '5px 20px',
        },
        sizeSmall: {
          padding: '4px 14px',
        },
        sizeLarge: {
          padding: '8px 20px',
        },
      },
      MuiButtonBase: {
        root: {
          margin: null,
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: `rgba(${isDark ? '255, 255, 255,' : '0, 0, 0,'} 0.3)`,
        },
        inputMarginDense: {
          height: 'inherit',
          fontSize: '14px',
        },
      },
      MuiInputBase: {
        input: {
          color: isDark ? '#fff' : '#1C2A33',
        },
      },
      MuiTypography: {
        overline: {
          fontSize: '11px',
        },
      },
      MuiAccordion: {
        root: {
          '&$expanded': {
            margin: 0,
          },
          '&::before': {
            display: 'none',
          },
          borderRadius: '13px',
        },
        rounded: {
          borderRadius: '13px',
        },
      },
      MuiAccordionDetails: {
        root: {
          display: 'block',
          padding: '0 20px 20px 20px',
        },
      },
      MuiAccordionSummary: {
        root: {
          minHeight: 40,
          '&$expanded': {
            minHeight: '40px !important',
          },
          padding: '21px 22px 19px',
        },
        content: {
          margin: 0,
          '&$expanded': {
            margin: '0 !important',
          },
        },
        expandIcon: {
          padding: 8,
        },
      },
      MuiTab: {
        root: { textTransform: 'inherit', maxWidth: 300 },
      },
      MuiLink: {
        root: { cursor: 'pointer' },
      },
    },
  };
}
