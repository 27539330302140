import styled from 'styled-components';

export const HeaderStyles = styled.div`
  // md --- Medium devices (tablets, 900px and up)
  .p-85 {
    padding-right: 85px;
  }
  @media (max-width: 990px) {
    .p-85 {
      padding-right: 0px;
    }
  }
  @media (max-width: 0px) {
    margin: 0px;
  }
  @media (min-width: ${props => props.mobile_screen}) {
    margin: 39px 150px 20px 150px;
    height: 80px;
  }
  @media (min-width: 0px) {
    .header_menu{
      padding: 24px 30px;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .header_menu{
      padding: 0px;
    }
  }
  .header_menu_close_button{
    font-size: 14px;
    font-style: normal;
    font-family: Montserrat, serif;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    text-transform: uppercase;
    color: #FF8383;
    border: 1px solid #FF8383;
    border-radius: 0px;
  }
  .header_menu_button{
    font-size: 14px;
    font-style: normal;
    font-family: Montserrat, serif;
    font-weight: 600;
    line-height: 20px;
    padding: 14px 28px;
    text-transform: uppercase;
    border: 1px solid #FFFFFF;
    color: white;
    filter: drop-shadow(0px 10px 30px rgba(56, 29, 219, 0.2));
    border-radius: 0px;
  }
  .header_order_call{
    font-size: 16px;
    font-style: normal;
    font-family: Montserrat, serif;
    font-weight: 700;
    line-height: 20px;
    padding: 14px 28px;
    text-transform: initial;
    width: 160px;
    height: 48px;
    color: ${props => props.pathname === '/' ? '#0063E4' : 'white'};
    border: ${props => props.pathname === '/' ? '1px solid #0063E4' : '1px solid #FFFFFF'};
    filter: drop-shadow(0px 10px 30px rgba(56, 29, 219, 0.2));
    border-radius: 0px;
  }
  @media (max-width: 1286px) {
    .header_order_call{
      color: white;
      border: 1px solid white;
    }
  }
  @media (max-width: 900px) {
    .header_order_call{
      display: none;
    }
    .header_comma{
      display: none;
    }
  }
  .header_buttons{
    color: white;
    display: block; 
    font-style: normal;
    font-family: Montserrat, serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px; 
    margin-right: 20px;
  }
  .header_comma{
    color: white;
    font-style: normal;
    font-family: Montserrat, serif;
    font-weight: 600; 
    font-size: 14px;
    line-height: 20px;
    margin-right: 20px;
    opacity: 0.3;
  }
`;
