import styled from 'styled-components';

export const Container2Styles = styled.div`
  @media (min-width: 0px) {
    .container_2{
      height: fit-content;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 30px;
      background-color: white;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .container_2{
      height: fit-content;
      padding-left: 150px;
      padding-right: 150px;
      padding-top: 77px;
      background-color: white;
    }
  }
  .container_2_1_title{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #1C2A33;
    opacity: 0.5;
    height: 50px;
    margin-bottom: 38px;
  }
  .container_2_1_img{
    margin: auto;
  }
  .container_2_paper{
    height: 100%;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Centering y-axis */
    align-items :center;
  }
  .container_2_2_img{
    filter: drop-shadow(0px 20px 100px rgba(34, 34, 34, 0.15));
  }
  @media (min-width: 0) {
    .container_2_2{
      padding-bottom: 55px;
      text-align: center;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .container_2_2{
      padding: 136px 0px;
    }
  }
  .container_2_3{
    padding: 86px 0px;
    position: relative;
  }
  .container_2_3_img{
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .container_2_3_1{
    padding-top: 80px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 130%;
    color: #1C2A33;
  }
  @media (min-width: 0px) {
    .container_2_3_1 {
      padding-top: 20px;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .container_2_3_1 {
      padding-top: 80px;
    }
  }
  .container_2_3_2{
    padding-top: 32px;
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1C2A33;
    opacity: 0.7;
  }
  .container_2_4{
    overflow: hidden;
    text-align: center;
  }
`;
