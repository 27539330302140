import Notice from "../Utils/Notice";
import jsonContent from "../../_helpers/contentText.json";

const validateEmail = (email) => {
    let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(email);
}
const validatePhoneNumber = (phone) => {
    let re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    return re.test(phone);
}

export async function SendToJivo(history, name = null, phone = null,
                                 email = null, company = null) {
    const contentText = jsonContent['SendToJivo'];
    if (!name || name.length === 0) {
        Notice.error(contentText['name_error']);
        return false;
    }
    if (!phone || phone.length === 0) {
        Notice.error(contentText['phone_error']);
        return false;
    }
    if (!validatePhoneNumber(phone)) {
        Notice.error(contentText['invalid_phone']);
        return false;
    }
    if (email && !validateEmail(email)) {
        Notice.error(contentText['invalid_email']);
        return false;
    }
    if (window.jivo_api) {
        let res = await window.jivo_api.sendMessage("Hello!");
        if (res.result === "ok") {
            res = await window.jivo_api.setContactInfo({
                "name": name,
                "email": email,
                "phone": phone,
                "description": `Company: ${company}`
            });
            if (res.result === "ok") {
                history.push("/thank_you");
                return true;
            } else {
                console.log(res.error);
                Notice.error(contentText['send_error'])
                return false;
            }
        } else {
            console.log(res.error);
            Notice.error(contentText['send_error'])
            return false;
        }
    } else {
        Notice.error(contentText['send_error'])
        return false;
    }
}
