import React, {useEffect, useState} from 'react';
import { StyledFooter } from './FooterStyle';
import {Button, Grid} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";

function Footer({mobile_screen, contentText}) {
    const contents = contentText['Footer'];
    let date = new Date();

    return (
        <StyledFooter mobile_screen={mobile_screen} id="CONTACTS">
            <Grid container spacing={0}  columns={10} className="footer">
                <Grid item xs={10} md={3} className="footer_1">
                    <Grid container spacing={0}  className="footer">
                        <Grid item md={12} className="footer_1_row_1">
                            <div className="footer_1_row_1_items">
                                <img src="./images/footer_logo.svg"/>
                            </div>
                        </Grid>
                        <Grid item md={12} className="footer_1_row_2">
                            {contents['rightsName']} {date.getFullYear()}, {contents['rights']}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10} md={4} className="footer_2">
                    <div className="footer_2_items" style={{marginTop: '66px'}}>
                        <div className="footer_2_address">
                            {contents['address']}
                        </div>
                        <div>
                            <IconButton 
                                color="primary" 
                                className="footer_social_buttons" 
                                onClick={() => {
                                    window.open('https://www.linkedin.com/company/cs-group-kz/', '_blank');
                                }}
                            >
                                <img src="./images/linkedin.svg"/>
                            </IconButton>
                        </div>
                        <div className="footer_2_email" style={{marginTop: '20px'}}>
                            {contents['email']}
                        </div>
                        <div className="footer_2_address" style={{marginTop: '20px'}}>
                            {contents['phone']}
                        </div>
                        <div className="footer_2_working_hours" style={{marginTop: '60px'}}>
                            {contents['working_title']}
                        </div>
                        <div>
                            <div className="footer_2_workdays">{contents['work_days']}</div>
                            <div className="footer_2_workhours">{contents['work_hours']}</div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={10} md={3} className="footer_3" sx={{textAlign: 'center'}}>
                    <Grid container spacing={0}  className="footer">
                        {contents['links'].map((link, index) => (
                            <Grid key={index} item xs={4} md={12} className="footer_1_row_1">
                                <NavLink to={link['hash'] ? "/#"+link['hash'] : link['link']}>
                                    <Button className="footer_buttons" sx={{ my: 2}}>
                                        {link['title']}
                                    </Button>
                                </NavLink>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </StyledFooter>
    );
}

export default Footer;
