import React, {useEffect} from "react";
import {HomePageStyles} from "./HomePageStyles";
import {useLocation} from "react-router-dom";
import Container1 from "./Containers/Container1/Container1";
import Container2 from "./Containers/Container2/Container2";
import Container3 from "./Containers/Container3/Container3";
import Container4 from "./Containers/Container4/Container4";
import Container5 from "./Containers/Container5/Container5";
import Container6 from "./Containers/Container6/Container6";

export  default function HomePage({setPathname, mobile_screen, mobile, contentText}) {
    let location = useLocation();
    useEffect(() => {
        setPathname(location.pathname);
        if(location['hash']){
            scrollTo(location['hash'].substring(1));
        }
    }, [location]);

    const scrollTo = (id) => {
        const divElement = document.getElementById(id);
        if (divElement) {
            divElement.scrollIntoView({behavior: 'smooth'});
        }
    }

    return(
        <HomePageStyles mobile_screen={mobile_screen}>
            <Container1 contentText={contentText} mobile_screen={mobile_screen}/>
            {/*<Container2 contentText={contentText} mobile={mobile} mobile_screen={mobile_screen}/>*/}
            <Container3 contentText={contentText} mobile={mobile} mobile_screen={mobile_screen}/>
            {/*<Container4 contentText={contentText} mobile_screen={mobile_screen}/>*/}
            <Container5 contentText={contentText} mobile={mobile} mobile_screen={mobile_screen}/>
            <Container6 contentText={contentText} mobile_screen={mobile_screen}/>
        </HomePageStyles>
    )
}
