import React, {useEffect} from "react";
import {Box, Grid} from "@mui/material";
import {useLocation} from "react-router-dom";
import {PortfolioStyles} from "./PortfolioStyles";
import Slide from "./Slides/Slide";

export default function Portfolio({setPathname, contentText, mobile_screen, mobile}){
    let location = useLocation();
    const contents = contentText['portfolio'];
    useEffect(() => {
        setPathname(location.pathname);
    }, [location]);

    return(
        <PortfolioStyles mobile_screen={mobile_screen}>
            <Box className="images">
                <div className="content">
                    {contents['components'].map((item, index) => {
                        return(
                          <div key={index}>
                            <Slide mobile_screen={mobile_screen} contents={item}/>
                              {index+1 !== contents['components'].length &&
                                <Grid item xs={12} md={12} className="divider">
                                  <Box component="img"
                                    className="divider-img"
                                    src={mobile ? "./images/mobile_divider.png" : "./images/divider.png"}></Box>
                                </Grid>
                              }
                          </div>
                        )
                    })
                    }
                </div>
            </Box>
        </PortfolioStyles>
    )
}
