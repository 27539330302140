import styled from 'styled-components';

export const Container5Styles = styled.div`
  margin-top: 100px;
  .container{
    height: fit-content;
    background-color: white;
  }
  .container_1{
    margin: 0px 235px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 130%;
    text-align: center;
    color: #1C2A33;
  }
  .container_2{
    margin: 0px 235px;
    padding-top: 40px;
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(28, 42, 51, 0.7);
  }
  .paper{
    width: 270px;
    height: 450px;
    background: #FFFFFF;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    margin: 0px 10px;
    padding: 0px;
    display: inline-block;
    text-align: center;
    overflow: clip;
    position: relative;
  }
  .paper_title{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 123.6%;
    color: #000000;
    padding-top: 24px;
    text-align: left;
    padding-left: 20px;
  }
  .paper_img-div {
    width: 100%;
    height: 350px;
  }
  .paper_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .paper_job_position{
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150.6%;
    color: #7B7B7B;
    padding-top: 10px;
    text-align: left;
    padding-left: 20px;
  }
  .socials{
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    margin: 0;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  }
  .team_facebook{
    margin-top: 29px;
  }
  .team_linkedin{
    margin-top: 74px;
  }
  .team_instagram{
    margin-top: 74px;
  }
  .team_twitter{
    margin-top: 119px;
  }
  .team_skype{
    margin-top: 164px;
  }
  .team_linkedin{
    height: 40px;
    width: 40px;
    border: 0px;
    border-radius: 0;
    background: url("/images/team_linkedin.png");
  }
  .team_linkedin:hover{
    background: url("/images/team_linkedin_hover.png");
  }
  .team_facebook{
    height: 40px;
    width: 40px;
    border: 0px;
    border-radius: 0;
    background: url("/images/team_facebook.svg");
  }
  .team_facebook:hover{
    background: url("/images/team_facebook_hover.svg");
  }
  .team_instagram{
    height: 40px;
    width: 40px;
    border: 0px;
    border-radius: 0;
    background: url("/images/team_instagram.svg");
  }
  .team_instagram:hover{
    background: url("/images/team_instagram_hover.svg");
  }
  .team_twitter{
    height: 40px;
    width: 40px;
    border: 0px;
    border-radius: 0;
    background: url("/images/team_twitter.svg");
  }
  .team_twitter:hover{
    background: url("/images/team_twitter_hover.svg");
  }
  .team_skype{
    height: 40px;
    width: 40px;
    border: 0px;
    border-radius: 0;
    background: url("/images/team_skype.svg");
  }
  .team_skype:hover{
    background: url("/images/team_skype_hover.svg");
  }
  @media (min-width: 0px) {
    .container_1 {
      margin: 0px 80px;
    }
    .container_2{
      margin: 0px 30px;
      padding-top: 20px;
    }
    .divider{
      padding-top: 40px;
      text-align: center;
      overflow: hidden;
    }
    .container_3{
      margin: 30px 57px 0px 57px;
      text-align: center;
    }
  }
  @media (min-width: ${props => props.mobile_screen}) {
    .container_1 {
      margin: 0px 235px;
    }
    .container_2{
      margin: 0px 235px;
      padding-top: 40px;
    }
    .divider{
      padding-top: 100px;
      text-align: center;
      overflow: hidden;
    }
    .container_3{
      margin: 60px 150px 0px 150px;
      text-align: center;
    }
  }
`;
