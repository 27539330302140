import React from 'react';
import { Store } from 'react-notifications-component';
import { IconButton } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopy';

const setMessage = (type, message) => {
    return Store.addNotification({
        message: (
            <div>
                {message.length > 500 && (
                    <IconButton
                        onClick={() => {
                            document
                                .querySelectorAll('[class^=\'MuiDialog-container\']')
                                .forEach(elem => elem.removeAttribute('tabindex'));
                            const el = document.createElement('textarea');
                            el.value = message;
                            el.setAttribute('readonly', '');
                            el.style.position = 'absolute';
                            el.style.left = '-9999px';
                            document.body.appendChild(el);
                            el.select();
                            document.execCommand('copy');
                            document.body.removeChild(el);
                        }}
                        className="copy-message"
                        size="small"
                        children={<CopyIcon style={{ fontSize: 16 }} />}
                        style={{ color: 'white' }}
                    />
                )}
                <div className="message-body">{message}</div>
            </div>
        ),
        type: type,
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__zoomOut'],
        dismiss: {
            click: message.length <= 200,
            duration: 10000,
            pauseOnHover: true,
            showIcon: true
        },
        slidingEnter: { duration: 0 },
        slidingExit: { duration: 300 }
    });
};

const Notice = {
    info: message => setMessage('info', message),
    success: message => setMessage('success', message),
    error: message => setMessage('danger', message),
    warning: message => setMessage('warning', message)
};

export default Notice;
